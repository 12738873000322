





















































import {Component, VModel, Vue} from 'vue-property-decorator';
import {WEEKDAYS} from '@/Constants';
import moment from 'moment';
import CleanTime from '@/models/CleanTime';


interface WeekDay {
  day: string;
  values: CleanTimeTime[];
}

// If using cleanTime.times.occurrence.start + end does not work, because at edit, the display values of the timePickers
// does not change
interface CleanTimeTime {
  cleanTime: CleanTime;
  valid: boolean;
  time: {from: string, to: string};
}

@Component({
  components: {
    MenuWithPickerComponent: () => import(
        '@/components/shared/MenuWithPicker.component.vue'),
  },
})
export default class WorkingTimeComponent extends Vue {

  @VModel({default: () => []})
  public availableCleanTimes!: CleanTime[];

  private selectedDays: WeekDay[] = [];

  private sum: number = 0;

  /**
   * Saves whole days or just occasionally cleanTimes
   * @private
   */
  private deletedTimes: { savedDays: WeekDay[], cleanTimes: CleanTime[] } = {savedDays: [], cleanTimes: []};

  private get weekdays() {
    return WEEKDAYS.map((weekday) => {
      return {day: weekday, selected: this.selectedDays.findIndex((day) => day.day === weekday) > -1};
    });
  }

  public mounted() {
    this.sum = 0;
    this.weekdays.forEach((weekday) => {
      // parser because cleanTime.setTime is not a function error...
      const values = this.availableCleanTimes.map((cleanTime) => this.fillCleanTime(CleanTime.parseFromObject(cleanTime)))
        .filter((userCleanTime) => userCleanTime.cleanTime.byWeekDay!.includes(weekday.day));
      values.forEach((value) => {
        this.addToSum(value.cleanTime);
      });
      // If the day has cleanTimes select it and use the cleanTime array
      if (values.length > 0) {
        weekday.selected = true;
        this.selectedDays.push({day: weekday.day, values});
        this.sortSelectedDays();
      }
    });
  }

  private addTime(cleanTimeIndex: number) {
    const day = this.selectedDays[cleanTimeIndex].day;
    this.selectedDays[cleanTimeIndex].values.push(this.fillCleanTime(new CleanTime(), [day]));
  }

  private removeTime(cleanTimeIndex: number, timeIndex: number) {
    this.deletedTimes.cleanTimes.push(this.selectedDays[cleanTimeIndex].values.splice(timeIndex, 1)[0].cleanTime);
    this.saveSelectedDays();
    this.emitDeletedCleanTimes();
    if (this.selectedDays[cleanTimeIndex].values.length < 1) {
       this.selectedDays.splice(cleanTimeIndex, 1);
   }
  }
  private saveSelectedDays() {
    const savedTimes: CleanTime[] = [];
    this.sum = 0;
    for (const day of this.selectedDays) {
      this.convertToCleanTimes(day).forEach((value) => {
        if (value.valid) {
          savedTimes.push(value.cleanTime);
          this.addToSum(value.cleanTime);
        }
      });
    }
    this.$emit('maxWorkHours', this.sum);
    // send savedTimes to userManageContent and change the array of the availableCleanTimes
    this.$emit('save', savedTimes);
  }

  private selectWeekday(day: { day: string, selected: boolean }) {
    if (day.selected) {
      // deselect
      const index = this.selectedDays.findIndex((value) => value.day === day.day);
      if (index > -1) {
        this.deletedTimes.savedDays = this.deletedTimes.savedDays.concat(this.selectedDays.splice(index, 1));
        this.saveSelectedDays();
        // send the deleted cleanTimes to the userManageComponent
        this.emitDeletedCleanTimes();
      }
    } else {
      // select
      const index = this.deletedTimes.savedDays.findIndex((value) => value.day === day.day);
      if (index > -1) {
        this.selectedDays.push(this.deletedTimes.savedDays.splice(index, 1)[0]);
        this.sortSelectedDays();
        this.saveSelectedDays();
      } else {
        this.selectedDays.push({day: day.day, values: [this.fillCleanTime(new CleanTime(), [day.day])]});
        this.sortSelectedDays();
      }
    }
  }

  private addToSum(cleanTime: CleanTime) {
    const time = cleanTime.times[0];
    if (time && time.duration) {
      this.sum += time.duration / (1000 * 60 * 60);
      this.sum = Math.round(this.sum * 100) / 100;
    }
  }

  /**
   * Sets a new time entry for the cleanTime based on two HH:mm time strings
   * @param weekDay
   */
  private convertToCleanTimes(weekDay: WeekDay): Array<{cleanTime: CleanTime, valid: boolean}> {
    return weekDay.values.map((value) => {
      value.cleanTime.setTime(value.time.from, value.time.to);
      value.valid = true;
      // If start or end time does not exist, the entry is invalid
      if (!value.time.to || !value.time.from) {
        value.valid = false;
      }
      return {cleanTime: value.cleanTime, valid: value.valid};
    });
  }

  private fillCleanTime(cleanTime: CleanTime, byWeekDay: string[] = []) {
    if (!cleanTime.byWeekDay || cleanTime.byWeekDay.length === 0) {
      cleanTime.byWeekDay =  byWeekDay;
    }
    const times = cleanTime.times[0];
    let start = '';
    let end = '';
    if (times) {
      const startTime = moment(times.hour + ':' + times.minute, 'HH:mm');
      end = startTime.clone().add(times.duration, 'ms').format('HH:mm');
      start = startTime.clone().format('HH:mm');
    }
    const time = {
      from: start,
      to: end,
    };
    // length > 0 means start and end moment is valid
    const valid: boolean = start.length > 0 && end.length > 0;
    return {time, valid, cleanTime};
  }

  private sortSelectedDays() {
    this.selectedDays.sort((a, b) => {
      const aIndex = WEEKDAYS.findIndex((weekday) => a.day === weekday);
      const bIndex = WEEKDAYS.findIndex((weekday) => b.day === weekday);
      return aIndex > bIndex ? 1 : -1;
    });
  }

  private emitDeletedCleanTimes() {
    const cleanTimes: CleanTime[] = [];
    this.deletedTimes.savedDays.forEach((weekDay) => {
      cleanTimes.push(...this.convertToCleanTimes(weekDay).map((value) => value.cleanTime));
    });
    this.$emit('deleteCleanTime', cleanTimes.concat(this.deletedTimes.cleanTimes));
  }
}
